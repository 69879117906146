.printpage {
    display: none;
}

@media print {
    .onprint {
        display: none;
    }

    .printpage {
        display: block;
    }

    @page {
        margin-top: 0cm;
        margin-left: 0cm;
        margin-right: 0cm;
    }
}